<template>
  <div class="parlay-info">
    <div class="parlay-info__crown-cont">
      <div class="parlay-info__crown-cont__img"><img src="/images/crown.png" /></div>
    </div>
    <div class="parlay-info__title-cont">
      <template v-if="!isMobile"
        >Sección habilitada solo para
        <span class="parlay-info__title-cont__bold"
          >Usuarios <template v-if="isGS1">GolTipster</template><template v-else>Premium</template></span
        ></template
      >
      <template v-else
        >Sección habilitada solo <br />para
        <span class="parlay-info__title-cont__bold"
          >Usuarios <template v-if="isGS1">GolTipster</template><template v-else>Premium</template></span
        ></template
      >
    </div>
    <div class="parlay-info__sub-cont">(99 Pesos al mes)</div>
    <div class="parlay-info__gain-cont">
      <template v-if="!isMobile">
        <div class="parlay-info__gain-cont__itempc">
          <div class="parlay-info__gain-cont__itempc__title">Ganancia:</div>
          <div class="parlay-info__gain-cont__itempc__money">${{ getParlayFreeGain }} MXN</div>
        </div>
      </template>
      <template v-else>
        <div class="parlay-info__gain-cont__item">
          <div class="parlay-info__gain-cont__item__title">Ganancia:</div>
          <div class="parlay-info__gain-cont__item__money">${{ getParlayFreeGain }} MXN</div>
        </div>
      </template>
    </div>
    <div class="parlay-info__contaccount">
      <template v-if="!isAccountMovil"
        ><span class="parlay-info__contaccount__bold" @click="onClickCreateAccount"
          >Crear cuenta <template v-if="isGS1">GolTipster</template><template v-else>Premium</template></span
        >&nbsp;&nbsp;<span class="parlay-info__contaccount__regular">¿Ya tienes una cuenta?</span>&nbsp;&nbsp;<span
          class="parlay-info__contaccount__bold"
          @click="onClickLogin"
          >Iniciar sesión</span
        ></template
      >
      <template v-else
        ><span class="parlay-info__contaccount__bold" @click="onClickCreateAccount"
          >Crear cuenta <template v-if="isGS1">GolTipster</template><template v-else>Premium</template></span
        ><br /><span class="parlay-info__contaccount__regular">¿Ya tienes una cuenta?</span><br /><span
          class="parlay-info__contaccount__bold"
          @click="onClickLogin"
          >Iniciar sesión</span
        ></template
      >
    </div>
    <div class="parlay-info__tournament-cont">
      <div class="parlay-info__tournament-cont__item">
        <span class="parlay-info__tournament-cont__item__title">Torneo:&nbsp;</span>
        <span class="parlay-info__tournament-cont__item__sub">{{ getParlayFreeSeason }}</span>
      </div>
    </div>
    <div class="parlay-info__matchday-cont">
      <div class="parlay-info__matchday-cont__item">
        <template v-if="!isMobile"
          ><span class="parlay-info__matchday-cont__item__title">Inversión inicial Jornada 1:&nbsp;</span
          ><span class="parlay-info__matchday-cont__item__sub">${{ getParlayFreeInvestment }} MXN</span></template
        >
        <template v-else
          ><span class="parlay-info__matchday-cont__item__title">Inversión inicial Jornada 1:&nbsp;</span><br /><span
            class="parlay-info__matchday-cont__item__sub"
            >${{ getParlayFreeInvestment }} MXN</span
          ></template
        >
      </div>
    </div>
    <div class="parlay-info__strategy-cont">
      <template v-if="!isMobile">
        <div
          class="parlay-info__strategy-cont__item"
          @click="
            clickOpenSteps();
            sendAnalyticsStrategy();
          "
        >
          <div class="parlay-info__strategy-cont__item__text">
            <span class="parlay-info__strategy-cont__item__text__bold">Estrategia:&nbsp;</span>Value Betting
          </div>
          <div class="parlay-info__strategy-cont__item__button" :class="showSteps ? 'trans' : ''">
            <img src="/images/icn_expand more.png" />
          </div>
        </div>
      </template>
      <template v-else>
        <div
          class="parlay-info__strategy-cont__item"
          @click="
            clickOpenSteps();
            sendAnalyticsStrategy();
          "
        >
          <div class="parlay-info__strategy-cont__item__text">
            <span class="parlay-info__strategy-cont__item__text__bold">Estrategia:</span>
          </div>
          <div class="parlay-info__strategy-cont__item__textmobil">
            <div class="parlay-info__strategy-cont__item__textmobil__text">Value Betting</div>
            <div class="parlay-info__strategy-cont__item__button" :class="showSteps ? 'trans' : ''">
              <img src="/images/icn_expand more.png" />
            </div>
          </div>
          <!--  -->
        </div>
      </template>
    </div>
    <div class="parlay-info__steps-cont" v-if="showSteps">
      <div class="parlay-info__steps-cont__frame">
        <div class="parlay-info__steps-cont__frame__title color1">Paso 1</div>
        <div class="parlay-info__steps-cont__frame__text">
          GolStats <span class="parlay-info__steps-cont__frame__text__bold">calcula</span> la probabilidad de cada
          apuesta disponible.
        </div>
      </div>
      <div class="parlay-info__steps-cont__frame">
        <div class="parlay-info__steps-cont__frame__title color2">Paso 2</div>
        <div class="parlay-info__steps-cont__frame__text">
          GolStats <span class="parlay-info__steps-cont__frame__text__bold">compara</span> su probabilidad con los
          momios ofrecidos por la casa de apuestas.
        </div>
      </div>
      <div class="parlay-info__steps-cont__frame">
        <div class="parlay-info__steps-cont__frame__title color3">Paso 3</div>
        <div class="parlay-info__steps-cont__frame__text">
          GolStats <span class="parlay-info__steps-cont__frame__text__bold">elige</span> las mejores apuestas.
        </div>
      </div>
      <div class="parlay-info__steps-cont__frame">
        <div class="parlay-info__steps-cont__frame__title color4">Paso 4</div>
        <div class="parlay-info__steps-cont__frame__text">
          <span class="parlay-info__steps-cont__frame__text__bold">El usuario gana dinero</span>
        </div>
      </div>
    </div>
    <div class="parlay-info__note-cont" v-if="showSteps">
      <div class="parlay-info__note-cont__info">
        <template v-if="!isTablet">
          <span class="parlay-info__note-cont__info__bold">NOTA:</span> Los parlays se pueden personalizar, por lo que
          muchos usuarios ganaron mucho más dinero que el mencionado arriba. <br />Los resultados aquí mostrados
          corresponden a apuestas Parlay de bajo riesgo sugeridas por GolStats.
        </template>
        <template v-else>
          <span class="parlay-info__note-cont__info__bold">NOTA:</span> Los parlays se pueden personalizar, por lo que
          muchos usuarios ganaron mucho más dinero que el mencionado arriba. Los resultados aquí mostrados corresponden
          a apuestas Parlay de bajo riesgo sugeridas por GolStats.
        </template>
      </div>
    </div>
    <div class="parlay-info__button-cont">
      <div
        class="parlay-info__button-cont__button"
        @click="
          onShowClick();
          sendAnalyticsBreakdown();
        "
      >
        <template v-if="!isMobile">Desglose de cada Parlay por jornada</template
        ><template v-else>Desglose Parlay por jornada</template> <img src="/images/icn_next.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import {
  parlayStrategy,
  parlayBreakdown,
} from /*  parlayBreakdownSeeMore,
         parlayDetails,
         parlayDiversification */ '@/utils/analytics';

export default {
  name: 'ParlayInfo',
  components: {},
  data() {
    return {
      showSteps: false,
    };
  },
  computed: {
    ...mapState(['displayWidth']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    ...mapGetters('betsGeneral', ['getParlayFreeGain', 'getParlayFreeInvestment', 'getParlayFreeSeason']),
    ...mapGetters('loginGeneral', ['isGS1']),
    isMobile() {
      return this.displayWidth < 568;
    },
    isTablet() {
      return this.displayWidth < 730;
    },
    isAccountMovil() {
      return this.displayWidth < 668;
    },
  },
  watch: {},
  methods: {
    ...mapMutations('auth', ['SET_AUTH_COMPONENT']),
    ...mapMutations(['SET_PREMIUM_MESSAGE_V2']),
    ...mapMutations('general', ['setSectionAnalytics']),
    clickOpenSteps() {
      this.showSteps = !this.showSteps;
    },
    onShowClick() {
      this.$emit('show');
    },
    onClickCreateAccount() {
      this.setSectionAnalytics('ParlayV3');
      this.SET_AUTH_COMPONENT('SelectPlan');
      this.SET_PREMIUM_MESSAGE_V2(false);
    },
    onClickLogin() {
      this.SET_AUTH_COMPONENT('Login');
      this.SET_PREMIUM_MESSAGE_V2(false);
    },
    sendAnalyticsStrategy() {
      parlayStrategy({
        is_premium: this.getPremiumAccount,
        golstats: this.getIsGolstats,
      });
    },
    sendAnalyticsBreakdown() {
      parlayBreakdown({
        is_premium: this.getPremiumAccount,
        golstats: this.getIsGolstats,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.trans {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.color1 {
  color: #6891fc;
}
.color2 {
  color: #9755ec;
}
.color3 {
  color: #faac4b;
}
.color4 {
  color: #aedb36;
}
.parlay-info {
  width: 100%;
  height: auto;
  padding-bottom: 60px;
  &__crown-cont {
    width: 100%;
    height: 151px;
    margin-top: 35px;
    display: inline-flex;
    justify-content: center;
    @media screen and (max-width: 768px) {
      height: 139px;
    }
    @media screen and (max-width: 668px) {
      height: 127px;
    }
    @media screen and (max-width: 368px) {
      height: 116px;
    }
    &__img {
      width: 151px;
      height: 151px;
      @media screen and (max-width: 768px) {
        width: 139px;
        height: 139px;
      }
      @media screen and (max-width: 668px) {
        width: 127px;
        height: 127px;
      }
      @media screen and (max-width: 368px) {
        width: 116px;
        height: 116px;
      }
    }
  }
  &__title-cont {
    width: 100%;
    height: 24px;
    margin-top: 10px;
    font-family: 'Heebo';
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    text-align: center;
    color: #132839;
    &__bold {
      font-family: 'Roboto-Black';
      font-weight: bold;
    }
    @media screen and (max-width: 768px) {
      font-size: 28px;
    }
    @media screen and (max-width: 668px) {
      font-size: 25px;
    }
    @media screen and (max-width: 568px) {
      height: 48px;
    }
    @media screen and (max-width: 368px) {
      font-size: 23px;
    }
  }
  &__sub-cont {
    width: 100%;
    height: 24px;
    margin-top: 20px;
    font-family: 'Roboto-Regular';
    font-size: 25px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    text-align: center;
    color: #132839;
    @media screen and (max-width: 768px) {
      font-size: 23px;
    }
    @media screen and (max-width: 668px) {
      font-size: 21px;
    }
    @media screen and (max-width: 368px) {
      font-size: 19px;
    }
  }
  &__contaccount {
    width: 100%;
    height: 59px;
    margin-top: 48px;
    line-height: 48px;
    @media screen and (max-width: 668px) {
      line-height: 29px;
      height: 94px;
    }
    &__bold {
      font-family: 'Roboto-Bold';
      font-size: 25px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      color: #132839;
      text-decoration: underline;

      @media screen and (max-width: 768px) {
        font-size: 22px;
      }
      @media screen and (max-width: 668px) {
        font-size: 25px;
      }
      @media screen and (max-width: 328px) {
        font-size: 23px;
      }
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
    &__regular {
      font-family: 'Roboto-Regular';
      font-size: 25px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;

      letter-spacing: normal;
      text-align: center;
      color: #132839;
      @media screen and (max-width: 768px) {
        font-size: 22px;
      }
      @media screen and (max-width: 668px) {
        font-size: 25px;
      }
      @media screen and (max-width: 328px) {
        font-size: 22px;
      }
    }
  }
  &__gain-cont {
    width: 100%;
    height: 94px; //143
    margin-top: 37px;
    display: inline-flex;
    justify-content: center;
    @media screen and (max-width: 768px) {
      height: 133px;
    }
    @media screen and (max-width: 668px) {
      height: 123px;
    }
    @media screen and (max-width: 568px) {
      height: 113px;
    }
    @media screen and (max-width: 368px) {
      height: 104px;
    }
    &__itempc {
      width: 536px;
      height: 94px;
      border-radius: 16px;
      box-shadow: 0 4px 15px 0 rgba(154, 188, 60, 0.35);
      background-color: #cbee6b;
      display: inline-flex;
      justify-content: center;

      &__title {
        width: auto;
        height: 94px;
        font-family: 'Roboto-Regular';
        font-size: 37px;
        font-weight: normal;
        font-stretch: normal;
        padding-right: 7px;
        font-style: normal;
        line-height: 94px;
        letter-spacing: -0.74px;
        text-align: center;
        color: #132839;
      }
      &__money {
        width: auto;
        height: 94px;
        font-family: 'Roboto-Black';
        font-size: 56px;
        font-weight: 900;
        padding-left: 7px;
        font-stretch: normal;
        font-style: normal;
        line-height: 94px;
        letter-spacing: -2.41px;
        text-align: center;
        color: #132839;
      }
    }
    &__item {
      width: 366px;
      height: 143px;
      border-radius: 16px;
      box-shadow: 0 4px 15px 0 rgba(154, 188, 60, 0.35);
      background-color: #cbee6b;
      @media screen and (max-width: 768px) {
        width: 336px;
        height: 133px;
      }
      @media screen and (max-width: 668px) {
        width: 306px;
        height: 123px;
      }
      @media screen and (max-width: 568px) {
        width: 286px;
        height: 113px;
      }
      @media screen and (max-width: 368px) {
        width: 262px;
        height: 104px;
      }
      &__title {
        width: 100%;
        height: 35px;
        margin-top: 20px;
        font-family: 'Roboto-Regular';
        font-size: 37px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 35px;
        letter-spacing: -0.74px;
        text-align: center;
        color: #132839;
        @media screen and (max-width: 768px) {
          height: 32px;
          line-height: 32px;
          font-size: 33px;
          margin-top: 19px;
        }
        @media screen and (max-width: 668px) {
          height: 30px;
          line-height: 30px;
          font-size: 29px;
          margin-top: 17px;
        }
        @media screen and (max-width: 568px) {
          height: 28px;
          line-height: 28px;
          font-size: 26.5px;
          margin-top: 15px;
        }
        @media screen and (max-width: 368px) {
          height: 26px;
          line-height: 26px;
          font-size: 26.5px;
          margin-top: 14px;
        }
      }
      &__money {
        width: 100%;
        height: 48px;
        margin-top: 12px;
        font-family: 'Roboto-Black';
        font-size: 56px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 48px;
        letter-spacing: -2.41px;
        text-align: center;
        color: #132839;
        @media screen and (max-width: 768px) {
          height: 45px;
          line-height: 45px;
          font-size: 51.1px;
          margin-top: 11px;
        }
        @media screen and (max-width: 668px) {
          height: 42px;
          line-height: 42px;
          font-size: 48.1px;
          margin-top: 10px;
        }
        @media screen and (max-width: 568px) {
          height: 39px;
          line-height: 39px;
          font-size: 45.1px;
          margin-top: 9px;
        }
        @media screen and (max-width: 368px) {
          height: 35px;
          line-height: 35px;
          font-size: 40.1px;
          margin-top: 8px;
        }
      }
    }
  }
  &__tournament-cont {
    width: 100%;
    height: 58px;
    margin-top: 58px;
    display: inline-flex;
    justify-content: center;
    &__item {
      width: 565px;
      height: 58px;
      background-color: #f5f8fe;
      line-height: 58px;
      @media screen and (max-width: 768px) {
        width: 485px;
      }
      @media screen and (max-width: 668px) {
        width: 415px;
      }
      @media screen and (max-width: 568px) {
        width: 345px;
      }
      @media screen and (max-width: 368px) {
        width: 260px;
      }
      &__title {
        font-family: 'Roboto-Bold';
        font-size: 32px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.64px;
        text-align: center;
        color: #132839;
        @media screen and (max-width: 768px) {
          font-size: 30px;
        }
        @media screen and (max-width: 668px) {
          font-size: 28px;
        }
        @media screen and (max-width: 568px) {
          font-size: 26px;
        }
        @media screen and (max-width: 368px) {
          font-size: 24px;
        }
      }
      &__sub {
        font-family: 'Roboto-Medium';
        font-size: 32px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.56;
        letter-spacing: -0.64px;
        text-align: center;
        color: #132839;
        @media screen and (max-width: 768px) {
          font-size: 30px;
        }
        @media screen and (max-width: 668px) {
          font-size: 28px;
        }
        @media screen and (max-width: 568px) {
          font-size: 26px;
        }
        @media screen and (max-width: 368px) {
          font-size: 24px;
        }
      }
    }
  }
  &__matchday-cont {
    width: 100%;
    height: 58px;
    margin-top: 4px;
    display: inline-flex;
    justify-content: center;
    @media screen and (max-width: 568px) {
      height: 86px;
    }
    &__item {
      width: 565px;
      height: 58px;
      background: #fff;
      border: solid 3px #f5f8fe;
      line-height: 58px;
      @media screen and (max-width: 768px) {
        width: 485px;
      }
      @media screen and (max-width: 668px) {
        width: 415px;
      }
      @media screen and (max-width: 568px) {
        width: 345px;
        height: 86px;
      }
      @media screen and (max-width: 368px) {
        width: 260px;
      }
      &__title {
        font-family: 'Roboto-Medium';
        font-size: 23px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.78;
        letter-spacing: -0.46px;
        text-align: center;
        color: #132839;
        @media screen and (max-width: 768px) {
          font-size: 22px;
        }
        @media screen and (max-width: 668px) {
          font-size: 21px;
        }
        @media screen and (max-width: 568px) {
          width: 100%;
          margin-top: 20px;
          float: left;
        }
        @media screen and (max-width: 368px) {
          font-size: 20px;
        }
      }
      &__sub {
        font-family: 'Roboto-Medium';
        font-size: 23px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.78;
        letter-spacing: -0.46px;
        text-align: center;
        color: #132839;
        @media screen and (max-width: 568px) {
          width: 100%;
          margin-top: -15px;
          float: left;
        }
      }
    }
  }
  &__strategy-cont {
    width: 100%;
    height: 48px;
    margin-top: 66px;
    display: inline-flex;
    justify-content: center;
    @media screen and (max-width: 568px) {
      height: 109px;
    }
    &__item {
      width: 412px;
      height: 48px;
      line-height: 48px;
      display: inline-flex;
      justify-content: space-between;
      @media screen and (max-width: 768px) {
        width: 382px;
      }
      @media screen and (max-width: 668px) {
        width: 352px;
      }
      @media screen and (max-width: 568px) {
        width: 302px;
        height: 109px;
        display: inline-block;
      }
      @media screen and (max-width: 368px) {
        width: 256px;
      }
      &__text {
        width: 364px;
        height: 48px;
        font-size: 33px;
        font-family: 'Heebo';
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 48px;
        letter-spacing: -0.66px;
        text-align: center;
        color: #313a42;
        @media screen and (max-width: 768px) {
          font-size: 31px;
          width: 336px;
        }
        @media screen and (max-width: 668px) {
          font-size: 29px;
          width: 304px;
        }
        @media screen and (max-width: 568px) {
          font-size: 31px;
          width: 100%;
        }
        @media screen and (max-width: 368px) {
          font-size: 28px;
        }
        &__bold {
          font-weight: bold;
        }
      }
      &__textmobil {
        width: 100%;
        height: 60px;
        font-size: 31px;
        display: inline-flex;
        justify-content: center;
        @media screen and (max-width: 368px) {
          font-size: 28px;
        }
        &__text {
          font-family: 'Heebo';
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: -0.66px;
          text-align: center;
          color: #313a42;
          padding-left: 9px;
          padding-right: 9px;
          line-height: 58px;
        }
      }
      &__button {
        width: 48px;
        height: 48px;
        border-radius: 8px;
        background-color: #ecf0f2;

        @media screen and (max-width: 568px) {
          margin-top: 10px;
          width: 40px;
          height: 40px;
        }
        img {
          margin-top: 9px;
          margin-left: 2px;
          @media screen and (max-width: 568px) {
            margin-top: 4px;
            margin-left: -2px;
          }
        }
        &:hover {
          background: #e2e6e9;
          cursor: pointer;
        }
      }
    }
  }
  &__steps-cont {
    width: 100%;
    height: 242px;
    margin-top: 48px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 0px;
    @media screen and (max-width: 1200px) {
      grid-column-gap: 20px;
    }
    @media screen and (max-width: 1110px) {
      grid-column-gap: 5px;
    }

    @media screen and (max-width: 1068px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 30px;
      height: auto;
    }
    @media screen and (max-width: 568px) {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(4, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 30px;
    }
    &__frame {
      width: 256px;
      height: 242px;
      border-radius: 24px;
      box-shadow: 0 7px 24px 0 rgba(84, 124, 171, 0.12);
      background-color: #fff;
      display: inline-block;
      margin: 0 auto;
      @media screen and (max-width: 1068px) {
        width: 92%;
      }
      &__title {
        width: 100%;
        height: 20px;
        margin-top: 50px;
        font-family: 'Heebo';
        font-size: 25px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 23px;
        letter-spacing: -0.5px;
        text-align: center;
      }
      &__text {
        width: 100%;
        height: auto;
        margin-top: 34px;
        align-self: stretch;
        flex-grow: 0;
        font-family: 'Heebo';
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.47;
        letter-spacing: normal;
        text-align: center;
        color: #132839;
        padding-left: 32px;
        padding-right: 32px;
        &__bold {
          font-family: 'Roboto-Black';
        }
      }
    }
  }
  &__note-cont {
    width: 100%;
    height: auto;
    margin-top: 50px;
    display: inline-flex;
    justify-content: center;
    @media screen and (max-width: 730px) {
      height: auto;
    }

    &__info {
      width: 100%;
      height: auto;
      font-family: 'Heebo';
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      border-radius: 12px;
      line-height: 1.6;
      letter-spacing: normal;
      text-align: center;
      color: #132839;
      border: solid 2px #e7efff;
      padding: 5px;
      @media screen and (max-width: 1068px) {
        width: 96%;
      }
      @media screen and (max-width: 930px) {
        font-size: 13px;
      }
      @media screen and (max-width: 830px) {
        font-size: 11.6px;
        line-height: 1.9;
      }
      @media screen and (max-width: 730px) {
        font-size: 15px;
        text-align: left;
        line-height: 1.4;
        padding-left: 9px;
        height: auto;
        padding-top: 9px;
        padding-bottom: 9px;
      }
      @media screen and (max-width: 568px) {
        width: 92%;
      }
      &__bold {
        font-weight: bold;
      }
    }
  }
  &__button-cont {
    width: 100%;
    height: 62px;
    margin-top: 58px;
    display: inline-flex;
    justify-content: center;
    &__button {
      width: 396px;
      height: 62px;
      border-radius: 10px;
      background-color: #0f92ff;
      font-family: 'Heebo';
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 62px;
      letter-spacing: -0.36px;
      text-align: center;
      color: #fff;
      display: inline-flex;
      justify-content: center;
      @media screen and (max-width: 400px) {
        width: 356px;
      }
      @media screen and (max-width: 368px) {
        width: 300px;
      }
      img {
        width: 33px;
        height: 32px;
        margin-top: 15px;
        margin-left: 15px;
      }
      &:hover {
        cursor: pointer;
        background: #0383ec;
      }
    }
  }
}
</style>
